// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------



//=====================****===================== 
//* browser prefix adding  
@mixin add-prefix($prop, $value) {
  -webkit-#{$prop}: #{$value};
  -moz-#{$prop}: #{$value};
  -ms-#{$prop}: #{$value};
  -o-#{$prop}: #{$value};
  #{$prop}: #{$value};
}


// Usage:
//***********

// div {
//   @include add-prefix(transform, scale3d(2.5, 2, 1.5));
// }

//*=====================****===================== 





//*=====================****===================== 
//Keyframes mixin with vendor prefix

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }

  @-moz-keyframes #{$animation-name} {
    @content;
  }

  @-ms-keyframes #{$animation-name} {
    @content;
  }

  @-o-keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

//========usages
// @include keyframes(fade-out) {
//   0% {
//     opacity: 1;
//   }

//   90% {
//     opacity: 0;
//   }
// }
//--------------------------------------------------
// //===Add animation to element
// .element {
//   width: 100px;
//   height: 100px;
//   background: black;
//   @include animation('fade-out 5s 3');
// }

//=====================****===================== 




//=====================****===================== 

//Responsive Breakpoints

@mixin breakpoint($point) {
  @if $point==sm {
    @media (min-width: 576px) {
      @content ;
    }
  }

  @else if $point==max-xsm {
    @media (max-width: 320px) {
      @content ;
    }
  }

  @else if $point==max-sm {
    @media (max-width: 575px) {
      @content ;
    }
  }

  @else if $point==md {
    @media (min-width: 768px) {
      @content ;
    }
  }

  @else if $point==max-md {
    @media (max-width: 767px) {
      @content ;
    }
  }

  @else if $point==lg {
    @media (min-width: 992px) {
      @content ;
    }
  }

  @else if $point==max-lg {
    @media (max-width: 991px) {
      @content ;
    }
  }

  @else if $point==mlg {
    @media (min-width: 1024px) {
      @content ;
    }
  }

  @else if $point==max-mlg {
    @media (max-width: 1023px) {
      @content ;
    }
  }

  @else if $point==xl {
    @media (min-width: 1200px) {
      @content ;
    }
  }

  @else if $point==max-xl {
    @media (max-width: 1199px) {
      @content ;
    }
  }

  @else if $point==xxl {
    @media (min-width: 1400px) {
      @content ;
    }
  }

  @else if $point==max-xxl {
    @media (max-width: 1399px) {
      @content ;
    }
  }

  @else if $point==xxxl {
    @media (min-width: 1600px) {
      @content ;
    }
  }

  @else if $point==max-xxxl {
    @media (max-width: 1599px) {
      @content ;
    }
  }
}


// Usage
//************

//  .selector {
//   @include breakpoint(md) {
//     font-size: 3rem;
//   }
// }

//*=====================****===================== 





//*=====================****===================== 
//*Retina ready image 
@mixin retina-image($image, $width, $height) {

  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    background-image: url($image);
    background-size: $width $height;
  }
}

// Usage:
// ************

// .image {
//   background: url("my-image.png") no-repeat;
//   @include retina-image("my-image2x.png", 1000px, 500px);
// }

//*=====================****===================== 





//*=====================****===================== 
//* Arrow with border 
@mixin arrow($direction: down, $size: 5px, $color: $theme-color) {
  width: 0;
  height: 0;

  @if ($direction==left) {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
  }

  @else if ($direction==right) {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  }

  @else if ($direction==down) {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  }

  @else {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  }
}


// Usage:
// *************

//==----without arguments (default)

// div {
//   @include arrow();
// }


//==----with custom arguments

// div {
//   @include arrow(up, 10px, #efefef);
// }


//*=====================****===================== 




//*=====================****===================== 
//*Creating box 
@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}


//* ===== Usage ===== 

//====---You can pass width && height

//   @include box(200px, 300px);


//====or just pass width and the height

//      will default to the width value */
//   @include box(200px);


//*=====================****===================== 



//*=====================****===================== 
//*backgorund size cover

@mixin background-cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

//* ===== Usage ===== 

// div {
//   background-image: url("cute-doggo.png");
//   @include background-cover;
// }

//*=====================****===================== 





//*=====================****===================== 
//*Hover effect for no-touch device only. (touch screen device dont have any hover state)

@mixin hover {
  @media not all and (pointer: coarse) {
    &:hover {
      @content;
    }
  }
}

// button {
//   color: #fff;
//   background-color: #252525;
//   padding: .25em .75em;

//    @include hover {
//     // ---Instead of &:hover use @include hover
//     color: #000;
//     background-color: #fff;
//   }
// }

//*=====================****===================== 