.sale {
    overflow: hidden;

    .section-header {
        @extend %mb-10;
        @extend %text-left;

        .header-title {
            left: 15%;
            top: -50%;
        }
    }

    &__brand {
        .section-header {
            .header-title {
                left: 30%;
            }
        }

        .sale__wrapper {
            @include breakpoint(lg) {
                &:before {
                    display: none;
                }

                // &::after {
                //     position: absolute;
                //     right: 0;
                //     bottom: 0;
                //     content: "";
                //     width: 60%;
                //     height: 100%;
                //     background-image: url(../images/brand/bg-shape.png);
                //     @include background-cover;
                //     background-position: initial;
                // }
            }

        }
    }

    &__wrapper {
        padding: 50px 20px;
        background-color: $primary-color;
        @extend %p-rel;
        @include add-prefix(border-radius, 10px);

        @include breakpoint(md) {
            padding: 60px 50px;
        }

        @include breakpoint(lg) {
            &:before {
                position: absolute;
                content: "";
                top: 0;
                right: 12%;
                width: 33%;
                height: 100%;
                background-color: $theme-color;
                transform: skewX(-25deg);
            }
        }

        @include breakpoint(xl) {
            padding: 30px 70px;
        }


    }

    &__content {
        @include breakpoint(lg) {
            max-width: 84%;
        }

        >p {
            color: $white-color;
            @extend %mb-30;

            @include breakpoint(lg) {
                margin-bottom: 45px;
            }
        }
    }

    &__thumb {
        @extend %of-hidden;
        @extend %text-center;
        padding-inline: 50px;
    }

    &__brand_item {
        @extend %text-center;
    }

    &__brand_wrap {
        @extend %p-rel;
        padding-block: 30px;
        z-index: 1;
    }
}