/**
 * Basic typography style for copy text
 */


body {
  color: $text-color;
  font-size: 1rem;
  line-height: 1.7;
  font-family: $text-font;
  background-color: $body-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @extend %text-bold;
  font-family: $title-font;
  color: $white-color;
  line-height: 1.2;
}

h1 {
  @include font-size($fs-h1);
}

h2 {
  @include font-size($fs-h2);
}

h3 {
  @include font-size($fs-h3);
}

h4 {
  @include font-size($fs-h4);
}

h5 {
  @include font-size($fs-h5);
}

h6 {
  @include font-size($fs-h6);
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: $text-color;
  text-decoration: none;

  @include hover {
    color: $theme-color;
  }
}

ul,
ol {
  @extend %list-none;
  @extend %pl-0;
}