.team {
    @extend %of-hidden;
    @include background-cover;

    &__item {
        @extend %of-hidden;
        @include add-prefix(transition, $transition);
        background-image: linear-gradient(180deg, #EBB787 0%, #E97826 100%);
        border-radius: 4px;
        text-align: center;
        padding: 20px;

        &--bg-2 {
            background-image: linear-gradient(180deg, #BE1E2D 0%, #154579 100%);
        }

        &--bg-3 {
            background-image: linear-gradient(180deg, #F7EC95 0%, #399946 100%);
        }

        &--bg-4 {
            background-image: linear-gradient(180deg, #E9C226 0%, #BE1E2D 100%);
        }

        @include hover {
            @include add-prefix(transform, translateY(-5px));
        }
    }

    &__thumb {
        @extend %mb-20;

        img {
            border-radius: 4px;
            width: 100%;
            object-fit: cover;
        }
    }

    &__content {
        h4 {
            a {
                color: $white-color;
            }
        }

        p {
            @extend %mb-5;
            color: $white-color;
        }
    }
}