.banner {
    @extend %of-hidden;
    @extend %p-rel;
    @include background-cover;
    padding-block: 75px;


    &__content {
        @extend %p-rel;
        z-index: 1;

        h1 {
            @extend %mb-20;
            text-transform: capitalize;

            @include breakpoint(xxl) {
                max-inline-size: 90%;
            }

        }

        h1,
        p {
            color: $white-color;
        }

        p {
            @extend %mb-45;
            @include font-size(18px);
            line-height: 1.65;
            max-inline-size: 45ch;
        }
    }

    &__thumb {
        &-slider {
            @extend %of-hidden;
        }

        &-item {
            @extend %of-hidden;
            border-radius: 5px;

            img {
                @extend %w-100;
            }
        }
    }
}

.bg-attach--fixed {
    @include breakpoint(lg) {
        background-attachment: fixed;
    }

}